import moment from 'moment';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';

import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';
import { NgxPermissionsService } from 'ngx-permissions';

import { AuthService } from '../../data/auth/auth.service';
import { AppState } from '../../app.service';

import { LoginUser, LoginUserCache, LoginUserOrg, SingleSignOn } from '../../data/auth/auth.model';

@Component({
  selector: 'login-sso',
  styleUrls: [ './sso.component.scss' ],
  templateUrl: './sso.component.html',
  providers: [CookieService, AuthService]
})
export class LoginSSOComponent implements OnInit {

  public domain: string = '';
  public errorMessage?: string;
  public isLoading: boolean = false;
  public isLoggedIn: boolean = false;
  public returnUrl?: string = undefined;
  public user?: LoginUser;
  
  private code?: string;
  private adfsRedirectUrl!: string;
  
  constructor(
    private _titleService: Title,
    private _authService: AuthService,
    private _cookieService: CookieService,
    private _route: ActivatedRoute,
    private _appState: AppState,
    private _router: Router,
    private _permissionsService: NgxPermissionsService,
    private _translate: TranslateService,
  ) {}

  public ngOnInit() {
    const domain = this._route.snapshot.queryParams['domain'];
    const returnUrl = this._route.snapshot.queryParams['returnUrl'];
    const code = this._route.snapshot.queryParams['code'];
    const state = this._route.snapshot.queryParams['state'];
    const ssoCookie = this._cookieService.get('lrSSO');

    this.adfsRedirectUrl = `${window.location.origin}/login/sso`;

    this._translate.get('LOGIN.TITLE_SSO')
      .subscribe((t: string) => {
        this._titleService.setTitle(t);
      });
      
    if (domain) {
      this.domain = domain;
      console.info('domain from params:', this.domain);
    } else if (ssoCookie) {
      let sso = JSON.parse(ssoCookie);
      if (sso && sso.domain) {
        this.domain = sso.domain;
        console.info('domain from SSO Cookie:', this.domain);
      }
    }

    if (returnUrl) {
      this.returnUrl = returnUrl;
    }

    if (state) {
      console.info('processing state from params');
      try {
        let stateJSON = JSON.parse(decodeURIComponent(state));
        if (stateJSON.returnUrl) {
          this.returnUrl = stateJSON.returnUrl;
          console.info('returnUrl from state:', this.returnUrl);
        }
        if (stateJSON.domain) {
          this.domain = stateJSON.domain;
          console.info('domain from state:', this.domain);
        }
      } catch(e) {
        console.error(e);
      }
    }

    if (code) {
      this.code = code;
      console.info('code from params:', this.code);
      this.isLoading = true;
      this.login();
    }
  }

  public getCode() {
    this.errorMessage = '';
    if (this.domain.trim() === '') {
      this.errorMessage = this._translate.instant('LOGIN.FILL_DOMAIN');
      return;
    }
    this.isLoading = true;
    this._authService.getSingleSignOnDomain(this.domain).subscribe({
      next: (data: SingleSignOn) => {
        this._cookieService.set('lrSSO', JSON.stringify({ domain: this.domain, type: data.type, logoutUrl: data.logoutUrl }), {
          expires: moment().add(1, 'week').toDate()
        });

        let state: any = undefined;
        if (this.returnUrl || this.domain) {
          state = {};
          if (this.returnUrl) {
            state.returnUrl = this.returnUrl;
          }
          if (this.domain) {
            state.domain = this.domain;
          }
        }

        if (data.type == 1) {
          // ADFS_OAUTH2
          let authorizeParams: any = {
            response_type: 'code',
            client_id: data.clientId,
            resource: data.resourceId,
            redirect_uri: this.adfsRedirectUrl
          };
          if (state !== undefined) {
            authorizeParams['state'] = encodeURIComponent(JSON.stringify(state));
          }
          
          let url = `${data.url}/authorize?${this.encodeQueryData(authorizeParams)}`;
          window.location.href = url;

        } else if (data.type == 2) {
          // ENTRA_ID_OAUTH2
          let authorizeParams: any = {
            response_type: 'code',
            client_id: data.clientId,
            scope: 'openid profile email',
            response_mode: 'query',
            redirect_uri: this.adfsRedirectUrl,
          };
          if (state !== undefined) {
            authorizeParams['state'] = encodeURIComponent(JSON.stringify(state));
          }
          
          let url = `${data.url}/authorize?${this.encodeQueryData(authorizeParams)}`;
          window.location.href = url;
        } else {
          this.reset();
          this.errorMessage = this._translate.instant('LOGIN.ENTER_VALID_DOMAIN');
        }
      },
      error: (error) => {
        console.error(error);
        this.reset();
        switch(error.status) {
          default:
            this.errorMessage = this._translate.instant('LOGIN.ENTER_VALID_DOMAIN');
        }
      }
    })
  }

  private encodeQueryData(data: any) {
    const ret = [];
    for (let d in data)
      ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
    return ret.join('&');
 }

  private login() {
    if (this.code) {
      this._authService.singleSignOnLogin(this.domain, this.adfsRedirectUrl, this.code).subscribe(
        (data: LoginUser) => {
          this.user = data;
          if (data.orgs.length === 1) {
            this.proceedWithOrg(data.orgs[0]);
          } else {
            this.isLoggedIn = true;
          }
        },
        (error) => {
          console.error(error);
          this.reset();
          this.errorMessage = this._translate.instant('ERROR_TRY_AGAIN');
        }
      )
    }
  }

  public proceedWithOrg(org: LoginUserOrg) {
    if (!this.returnUrl) {
      this.returnUrl = this._authService.getAppPath(org.defaultAppId);
    }
    
    const userCache: LoginUserCache | undefined = this.user ? new LoginUserCache({
      user_lid: this.user.userLid,
      display_name: this.user.displayName,
      org: org.toJson()
    }) : undefined;

    if (userCache) {
      this._cookieService.set('lrUser', JSON.stringify(userCache.toJson()), {
        expires: moment().add(2, 'day').toDate()
      });
    }

    if (userCache) {
      this._appState.set('user_name', userCache.displayName);
      this._appState.set('user_id', userCache.userLid);
    }
    this._appState.set('orgId', org.orgLid);
    this._appState.set('provider', org.providerToJson());
    this._appState.set('org', org.toJson());
    this._permissionsService.loadPermissions(org.permissions);
    this._router.navigateByUrl(this.returnUrl);
  }

  private reset() {
    this.code = undefined;
    this.isLoading = false;
    this._cookieService.delete('lrSSO', '/');
  }
}
