import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { AppState } from '@app/app.service';
import { AuthService } from '@app/data/auth/auth.service';

import { AppId } from '@app/data/auth/app-id.model';
import { LoginUserOrg } from '@app/data/auth/auth.model';

@Component({
  selector: 'app-upgrade',
  styleUrls: [ './upgrade.component.scss' ],
  templateUrl: './upgrade.component.html',
  providers: [AuthService]
})
export class UpgradeComponent implements OnInit {

  public isSideBarOpened = false;
  public isLoaded = false;

  public appId!: AppId;
  public featureId?: number;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _authService: AuthService,
    private _appState: AppState,
  ) {}

  public onAppClicked() {
    this.isSideBarOpened = false;
  }

  public onHeaderMenuClicked() {
    this.isSideBarOpened = !this.isSideBarOpened;
  }

  ngOnInit() {
    this._route.queryParams
      .subscribe((queryParams: Params) => {
        if (queryParams['feature']) {
          this.featureId = +queryParams['feature'];
        }
        if (queryParams['app']) {
          this.appId = new AppId(+queryParams['app']);
          this.isLoaded = true;
        }
      });
  }

  public continue() {
    const orgJson = this._appState.get('org');
    if (orgJson) {
      const org: LoginUserOrg = new LoginUserOrg(orgJson);
      this._router.navigate(org.defaultAppId.getRouterLink());
    } else {
      this._router.navigate(['/']);
    }
  }
}
