import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { AppState } from '../app.service';
import { AuthService } from '../data/auth/auth.service';

import { AppId } from '../data/auth/app-id.model';
import { LoginUserOrg } from '../data/auth/auth.model';

@Component({
  selector: 'not-authorized',
  styleUrls: [ './not-authorized.component.scss' ],
  templateUrl: './not-authorized.component.html',
  providers: [AuthService]
})
export class NotAuthorizedComponent implements OnInit {

  public isSideBarOpened = false;
  public isLoaded = false;

  public appId!: AppId;

  continueRouterLink: Array<string> = ['/'];

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _authService: AuthService,
    private _appState: AppState,
  ) {}

  public onAppClicked() {
    this.isSideBarOpened = false;
  }

  public onHeaderMenuClicked() {
    this.isSideBarOpened = !this.isSideBarOpened;
  }

  ngOnInit() {
    this._route.queryParams
      .subscribe((queryParams: Params) => {
        if (queryParams['app']) {
          this.appId = new AppId(+queryParams['app']);
          this._updateAppInfo();
          this.isLoaded = true;
        }
      });
  }

  public continue() {
    const orgJson = this._appState.get('org');
    if (orgJson) {
      const org: LoginUserOrg = new LoginUserOrg(orgJson);
      this._router.navigate(org.defaultAppId.getRouterLink());
    } else {
      this._router.navigate(['/']);
    }
  }

  private _updateAppInfo() {
    this._authService.isAppEnabled(this.appId.id).then(isEnabled => {
      if (isEnabled) {
        this.continueRouterLink = this.appId.getRouterLink();
      }
    },
    (reason: any) => {
      console.error('Failed to get org for not-authorized');
      this.continueRouterLink = ['/'];
    });
  }

}
