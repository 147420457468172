<div class="leap-rail-app" (click)="onAppClicked()" [ngClass]="{'hidden': !isLoaded}">
  <generic-header [appId]="appId" [isOpened]="isSideBarOpened" (onMenuClicked)="onHeaderMenuClicked()"></generic-header>
  <generic-action-bar [breadcrumbs]="breadcrumbs"></generic-action-bar>
  <div class="status-body">
    <div class="card mb-3">
      <div class="card-body">
        <h3 class="card-title">{{ 'STATUS.CURRENT_STATUS_WTH_DATE' | translate: { date: lastUpdated | amDateFormat:'LLL' } }}</h3>
        <p class="card-text">
          {{ 'STATUS.LR_PUBLISHES' | translate }}
          {{ 'STATUS.CHECK_BACK_HERE' | translate }}
          {{ 'STATUS.EXPERIENCING_REAL_TIME_ISSUE' | translate | translateCut:0 }} 
          <a href="mailto:support@leaprail.com">{{ 'STATUS.EXPERIENCING_REAL_TIME_ISSUE' | translate | translateCut:1 }}</a>{{ 'STATUS.EXPERIENCING_REAL_TIME_ISSUE' | translate | translateCut:2 }}
        </p>
      </div>
    </div>
    <div class="card mb-3">
      <div class="card-body">
        <h5 class="card-title">{{ 'STATUS.LR_SERVICES' | translate }}</h5>
          <p class="card-text">
            {{ 'STATUS.ALL_NORMAL' | translate }}
          </p>
      </div>
    </div>
    <div class="card mb-3" *ngIf="integrations && integrations.length">
      <div class="card-body">
        <h5 class="card-title">{{ 'STATUS.EMR_CONNECTIVITY' | translate }}</h5>
        <div class="table-responsive-md">
          <p class="card-text" *ngIf="connectivityStatus == 1">{{ 'STATUS.EMR_NORMAL' | translate }}</p>
          <p class="card-text" *ngIf="connectivityStatus == 2">{{ 'STATUS.EMR_PARTIAL' | translate }}</p>
          <p class="card-text" *ngIf="connectivityStatus == 3">{{ 'STATUS.EMR_FAILED' | translate }}</p>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">{{ 'STATUS.DATA_FEED' | translate }}</th>
                <th scope="col">{{ 'STATUS' | translate }}</th>
                <th scope="col">{{ 'STATUS.LAST_MESSAGE' | translate }}</th>
                <th scope="col" *ngxPermissionsOnly="['IntegrationRequestLookup']">{{ 'STATUS.LAST_MESSAGE_RECEIVED' | translate }}</th>
                <th scope="col" *ngxPermissionsOnly="['IntegrationRequestLookup']">{{ 'STATUS.LAST_MESSAGE_ID' | translate }}</th>
                <th scope="col" *ngxPermissionsOnly="['IntegrationRequestLookup']">{{ 'STATUS.LAST_MESSAGE_DELAY' | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let integration of integrations">
                <td>{{ integration.requestTypeName }}</td>
                <ng-container *ngIf="integration.status">
                  <td *ngIf="!integration.stale"><span class="badge rounded-pill text-bg-success">{{ 'STATUS.IN_SYNC' | translate }}</span></td>
                  <td *ngIf="integration.stale"><span class="badge rounded-pill text-bg-danger">{{ 'STATUS.OUT_OF_SYNC' | translate }}</span></td>
                  <td><span tooltip="{{ (integration.status.lastMessageReceivedOccurred | amFromUnix) | amDateFormat:'LLL' }}">{{ (integration.status.lastMessageReceivedOccurred | amFromUnix) | amTimeAgo }}</span></td>
                  <td *ngxPermissionsOnly="['IntegrationRequestLookup']"><span tooltip="{{ (integration.status.lastMessageReceivedTs | amFromUnix) | amDateFormat:'LLL' }}">{{ (integration.status.lastMessageReceivedTs | amFromUnix) | amTimeAgo }}</span></td>
                  <td *ngxPermissionsOnly="['IntegrationRequestLookup']">{{ integration.status.lastMessageReceivedLid }}</td>
                  <td *ngxPermissionsOnly="['IntegrationRequestLookup']">{{ integration.status.lastMessageReceivedDelay | amDuration:'seconds' }}</td>
                </ng-container>
                <ng-container *ngIf="!integration.status">
                  <td><span class="badge rounded-pill text-bg-danger">{{ 'STATUS.OUT_OF_SYNC' | translate }}</span></td>
                  <td>{{ 'NA' | translate }}</td>
                  <td *ngxPermissionsOnly="['IntegrationRequestLookup']">{{ 'NA' | translate }}</td>
                  <td *ngxPermissionsOnly="['IntegrationRequestLookup']">{{ 'NA' | translate }}</td>
                  <td *ngxPermissionsOnly="['IntegrationRequestLookup']">{{ 'NA' | translate }}</td>
                </ng-container>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<app-sidebar [isOpened]="isSideBarOpened"></app-sidebar>
