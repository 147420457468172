import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgxPermissionsModule } from 'ngx-permissions';
import { TranslateModule } from '@ngx-translate/core';

import { SharedModule } from '@app/framework/shared.module';
import { UIModule } from '../../ui/ui.module';

import { SurgeryBlockOnDateModalComponent } from './surgery-block-on-date-modal.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    NgxPermissionsModule.forChild(),
    ModalModule,
    BsDropdownModule,
    TranslateModule,

    SharedModule,
    UIModule,
  ],
  declarations: [
    SurgeryBlockOnDateModalComponent
  ],
  exports: [
    SurgeryBlockOnDateModalComponent
  ]
})
export class SurgeryBlockOnDateModalModule {
}
