import { HttpClient } from "@angular/common/http";

import { TranslateLoader } from "@ngx-translate/core";
import { CookieService } from "ngx-cookie-service";
import { forkJoin, Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import merge from 'deepmerge';

import { LoginUserCache } from "./auth/auth.model";

// @ts-ignore
import packageObj from '../../../package.json';

const CUSTOM_TRANSLATION: any = {
  '10240343119234049_en': false,
  '57968646458180609_en': true,
  '664530690519335939_en': true,
  '673938834462868484_es': true,
  '854132751895364611_en': true,
}

export class LRTranslateHttpLoader implements TranslateLoader {
  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private prefix: string,
    private suffix: string,
  ) {}

  public getTranslation(lang: string): Observable<any> {
    const path = this.prefix + lang + this.suffix + '?version=' + packageObj.version;

    let requests = [
      this.http.get(path).pipe(
        catchError(res => {
          console.error("Something went wrong for the following translation file:", path);
          console.error(res.message);
          return of({});
        })
      )
    ];

    const cachedUser = this.cookieService.get('lrUser');
    if (cachedUser) {
      const user: LoginUserCache = new LoginUserCache(JSON.parse(cachedUser));

      if (CUSTOM_TRANSLATION[user.org.orgLid + '_' + lang]) {
        const orgPath = this.prefix + user.org.orgLid + '_' + lang + this.suffix + '?version=' + packageObj.version;
        requests.push(
          this.http.get(orgPath).pipe(
            catchError(res => {
              console.error("Something went wrong for the following translation file:", orgPath);
              console.error(res.message);
              return of({});
            })
          )
        );
      }
    }

    return forkJoin(requests).pipe(map(response => merge.all(response)));
  }
}