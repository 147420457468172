import moment from 'moment';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { NgxPermissionsService } from 'ngx-permissions';
import { TranslateService } from '@ngx-translate/core';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { esLocale } from 'ngx-bootstrap/locale';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';

import { AppState } from './app.service';
import { AuthService } from './data/auth/auth.service';

import { LoginUserOrg, LoginUserCache } from './data/auth/auth.model';

@Component({
  selector: 'app-root',
  encapsulation: ViewEncapsulation.None,
  styleUrls: [ './app.component.scss' ],
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

  constructor(
    private _appState: AppState,
    private _authService: AuthService,
    private _permissionsService: NgxPermissionsService,
    private _translate: TranslateService,
    private _localeService: BsLocaleService,
  ){
    const lang = this._translate.currentLang;
    
    moment.locale(lang);

    defineLocale('es', esLocale);
    this._localeService.use(lang);
  }

  public ngOnInit() {
    if (this._authService.isAuthenticated()) {
      const user: LoginUserCache | undefined = this._authService.getUser();
      if (user) {
        this._appState.set('user_name', user.displayName);
        this._appState.set('user_id', user.userLid);
      }
      this._authService.getOrg().then((org: LoginUserOrg | undefined) => {
        if (org) {
          this._appState.set('orgId', org.orgLid);
          this._appState.set('provider', org.providerToJson());
          this._appState.set('org', org.toJson());
          this._permissionsService.loadPermissions(org.permissions);
        }
      },
      (reason: any) => {
        console.error('Failed to get org for app component', reason);
      });
    }
  }
}