<div class="login-container">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="form-block">
          <div class="logo"></div>
          <div class="form-horizontal" *ngIf="!isLoggedIn">
            <form (ngSubmit)="login()" *ngIf="!isPassChange">
              <div class="row mb-3">
                <label class="col-sm-3 col-form-label" for="email">{{ 'LOGIN.EMAIL_ADDRESS' | translate }}</label>
                <div class="col-sm-8">
                  <div class="input-group">
                    <input type="text" name="email" [(ngModel)]="email" class="form-control" placeholder="{{ 'LOGIN.EMAIL_ADDRESS' | translate }}" required/>
                    <div class="input-group-text"><i class="fa fa-envelope fa-fw"></i></div>
                  </div>
                  
                </div>
              </div>
              <div class="row mb-3">
                <label class="col-sm-3 col-form-label" for="email">{{ 'LOGIN.PASSWORD' | translate }}</label>
                <div class="col-sm-8">
                  <div class="input-group">
                    <input type="password" name="password" [(ngModel)]="password" class="form-control" placeholder="{{ 'LOGIN.PASSWORD' | translate }}" required/>
                    <div class="input-group-text"><i class="fa fa-lock fa-fw"></i></div>
                  </div>
                  
                </div>
              </div>
              <div class="row mb-3">
                <div class="offset-sm-3 col-sm-8 button-row" [ngClass]="{'is-loading': isLoading}">
                  <button type="submit" class="btn btn-secondary btn-submit">{{ 'LOGIN.LOGIN' | translate }}</button>
                  <i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
                </div>
              </div>
              <div class="offset-sm-3 col-sm-8 error-message">{{errorMessage}}</div>
            </form>
            <div class="sso-block" *ngIf="!isLoggedIn && !isPassChange">
              <div class="form-group">
                <div class="offset-sm-3 col-sm-8 button-row" [ngClass]="{'is-loading': isLoading}">
                  {{ 'LOGIN.SSO' | translate | translateCut:0 }} <a [routerLink]="['/login/sso']" [queryParams]="{returnUrl: returnUrl ? returnUrl : ''}">{{ 'LOGIN.SSO' | translate | translateCut:1 }}</a>
                </div>
              </div>
            </div>
            <form (ngSubmit)="changePassword()" class="padded-form" *ngIf="isPassChange" autocomplete="off">
              <div class="form-group" [ngClass]="{'has-error': newPassword !== '' && !isPassOk}">
                <div class="info-content">
                  {{ 'LOGIN.MUST_CHANGE_PASSWORD' | translate }}
                </div>
                <input type="password" name="pwd"
                  placeholder="New Password" required
                  (keyup)="onPasswordChange($event)"
                  [(ngModel)]="newPassword" class="form-control" />
                <div class="error-message mt">
                  <div *ngFor="let suggestion of passwordSuggestions">{{suggestion}}</div>
                </div>
              </div>
              <div class="form-group">
                <div class="button-row mt" [ngClass]="{'is-loading': isLoading}">
                  <button type="submit" [disabled]="newPassword === '' || !isPassOk" class="btn btn-secondary btn-submit">{{ 'CONTINUE' | translate }}</button>
                  <i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
                </div>
              </div>
              <div class="offset-sm-3 col-sm-8 error-message">{{errorMessage}}</div>
            </form>
          </div>
          <div class="info-block" *ngIf="isLoggedIn">
            {{ 'LOGIN.WELCOME_BACK' | translate:{name: this.user?.displayName} }}
            <div class="info-content">
              {{ 'LOGIN.SELECT_DOMAIN' | translate }}
            </div>
            <div class="org-list-item" *ngFor="let org of this.user?.orgs">
              <button type="button" class="btn btn-secondary btn-org" (click)="proceedWithOrg(org)">{{org.orgName}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 text-center login-footer" [innerHTML]="'COPYRIGHT' | translate"></div>
    </div>
  </div>
</div>