import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthService } from './data/auth/auth.service';
import { HomeComponent } from './home';
import { LoginComponent } from './login';
import { LoginAdminComponent } from './login/admin';
import { LoginSSOComponent } from './login/sso';
import { LoginResetComponent } from './login/reset';
import { NotAuthorizedComponent } from './not-authorized';
import { StatusComponent } from './status';
import { UpgradeComponent } from './upgrade';
import { NoContentComponent } from './no-content';
import { MaintenanceComponent } from './maintenance/maintenance.component';

const routes: Routes = [
  { path: '',                                    canActivate: [AuthService], component: HomeComponent },
  { path: 'analytics',                           loadChildren: () => import('./+analytics/analytics.module').then(m => m.AnalyticsModule) },
  { path: 'board',                               loadChildren: () => import('./+board/board.module').then(m => m.BoardModule) },
  { path: 'management',                          loadChildren: () => import('./+management/management.module').then(m => m.ManagementModule) },
  { path: 'entity',                              loadChildren: () => import('./+entity/entity.module').then(m => m.EntityModule) },
  // { path: 'quality',                             loadChildren: () => import('./+quality/quality.module').then(m => m.QualityModule) },
  { path: 'surgery',                             loadChildren: () => import('./+surgery/surgery.module').then(m => m.SurgeryModule) },
  { path: 'shift',                               loadChildren: () => import('./+shift/shift.module').then(m => m.ShiftModule) },
  { path: 'login',                               component: LoginComponent },
  { path: 'login/admin',                         component: LoginAdminComponent },
  { path: 'login/sso',                           component: LoginSSOComponent },
  { path: 'password/reset',                      component: LoginResetComponent },
  { path: 'not-authorized',                      canActivate: [AuthService], component: NotAuthorizedComponent },
  { path: 'status',                              canActivate: [AuthService], component: StatusComponent },
  { path: 'upgrade',                             canActivate: [AuthService], component: UpgradeComponent },
  { path: '**',                                  component: NoContentComponent },
  // { path: '**',                                  component: MaintenanceComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
