import { Component, OnInit } from '@angular/core';
import { AuthService } from '../data/auth/auth.service';
import { Router } from '@angular/router';
import { LoginUserOrg } from '../data/auth/auth.model';

@Component({
  selector: 'app-home',
  template: ''
})

export class HomeComponent implements OnInit {

  constructor(
    private _authService: AuthService,
    private _router: Router,
  ) {}

  ngOnInit() {
    this._authService.getOrg().then((org: LoginUserOrg | undefined) => {
      this._router.navigate(org ? org.defaultAppId.getRouterLink() : ['/']);
    },
    (reason: any) => {
      console.error('Failed to get org for home component');
    });
  }
}