import { Component } from '@angular/core';

@Component({
  selector: 'no-content',
  template: `
    <div class="card-container">
      <h1>{{ 'PAGE_NOT_FOUND' | translate }}</h1>
    </div>
  `
})
export class NoContentComponent {

}
