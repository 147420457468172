import { ErrorHandler, Injectable } from '@angular/core';

import { datadogLogs } from '@datadog/browser-logs';

import { environment } from 'src/environments/environment';

// @ts-ignore
import packageObj from '../../../package.json';

@Injectable({
  providedIn: 'root'
})
export class DatadogErrorHandlerService implements ErrorHandler {

  private enabled: boolean = environment.production || environment.staging;
  constructor() {
    if (this.enabled) {
      datadogLogs.init({
        clientToken: 'pube7e91eed6b9c2e71cdd80d55cabb9f1a',
        site: 'datadoghq.com',
        forwardErrorsToLogs: true,
        service: 'leaprail-spa',
        env: environment.production ? "production" : (environment.staging ? "staging" : "dev"),
        version: packageObj.version,
      });
    } 
  }

  handleError(error: any): void {
    if (this.enabled) {
      // Log the error to Datadog
      datadogLogs.logger.error('Global Error Handler', {
        error: error instanceof Error ? error.message : error.toString(),
        stack: error instanceof Error ? error.stack : null,
      });
    }

    // Delegate to the default error handler behavior
    console.error('Global error caught by DatadogErrorHandler:', error);
  }

  debug(message: string, data: any) {
    if (this.enabled) {
      datadogLogs.logger.debug(message, data);
    }
  }
}
