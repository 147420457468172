<div class="sso-container">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="form-block">
          <div class="logo"></div>
          <div class="form-horizontal" *ngIf="!isLoggedIn">
            <form (ngSubmit)="getCode()">
              <div class="row mb-3">
                <label class="col-sm-3 col-form-label" for="domain">{{ 'LOGIN.DOMAIN' | translate }}</label>
                <div class="col-sm-8">
                  <input type="text" name="domain" [(ngModel)]="domain" class="form-control" placeholder="{{ 'LOGIN.DOMAIN' | translate }}" required/>
                  <i class="fa fa-globe helper-icon"></i>
                </div>
              </div>
              <div class="row mb-3">
                <div class="offset-sm-3 col-sm-8 button-row" [ngClass]="{'is-loading': isLoading}">
                  <button type="submit" class="btn btn-secondary btn-submit">{{ 'LOGIN.LOGIN' | translate }}</button>
                  <i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
                </div>
              </div>
              <div class="offset-sm-3 col-sm-8 error-message">{{errorMessage}}</div>
            </form>
            <div class="login-block" *ngIf="!isLoggedIn">
              <div class="row mb-3">
                <div class="offset-sm-3 col-sm-8 button-row" [ngClass]="{'is-loading': isLoading}">
                  {{ 'LOGIN.NORMAL_LOGIN' | translate | translateCut:0 }} <a [routerLink]="['/login']" [queryParams]="{returnUrl: returnUrl ? returnUrl : ''}">{{ 'LOGIN.NORMAL_LOGIN' | translate | translateCut:1 }}</a>
                </div>
              </div>
            </div>
          </div>
          <div class="info-block" *ngIf="isLoggedIn">
            {{ 'LOGIN.WELCOME_BACK' | translate:{name: this.user?.displayName} }}
            <div class="info-content">
              {{ 'LOGIN.SELECT_DOMAIN' | translate }}
            </div>
            <div class="org-list-item" *ngFor="let org of this.user?.orgs">
              <button type="button" class="btn btn-secondary btn-org" (click)="proceedWithOrg(org)">{{org.orgName}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 text-center login-footer" [innerHTML]="'COPYRIGHT' | translate"></div>
    </div>
  </div>
</div>