@import './base';

body.lr-app {
  font-family: 'Heebo', sans-serif;
  font-size: 14px;
  font-weight: 200;
  line-height: 1.42857143;
  background-color: $color-alice-blue;
  margin: 0;
  padding: 0;

  @media print{
    font-family: sans-serif;
    background-color: $color-white;
  }

  .btn.btn-secondary {
    @include button-base;
    background-color: $color-white;
  }

  // Progress bar override
  .progress {
    background-color: $color-wild-blue-yonder-20;
    margin: 0;
    height: 6px;
  }

  .presentation-news-item .progress {
    height: 15px;
  }

  // Modal override
  .modal-dialog {
    margin: 120px auto;
  }

  .picker-container .text-info {
    color: $color-electric-violet;
  }

  .has-error .form-control {
    border-color: $color-red-orange;
  }

}