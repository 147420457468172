export class OrgIntegrationRequestTypeStatus {
  lastMessageReceivedLid: string;
  lastMessageReceivedOccurred: number;
  lastMessageReceivedTs: number;
  lastMessageReceivedDelay: number;
  lastMessageProcessedLid: string;
  lastMessageProcessedOccurred: number;
  lastMessageProcessedTs: number;
  lastMessageProcessedDelay: number;

  constructor(d: any) {
    this.lastMessageReceivedLid = d.last_msg_received_lid;
    this.lastMessageReceivedOccurred = d.last_msg_received_occurred;
    this.lastMessageReceivedTs = d.last_msg_received_ts;
    this.lastMessageReceivedDelay = d.last_msg_received_delay;
    this.lastMessageProcessedLid = d.last_msg_processed_lid;
    this.lastMessageProcessedOccurred = d.last_msg_processed_occurred;
    this.lastMessageProcessedTs = d.last_msg_processed_ts;
    this.lastMessageProcessedDelay = d.last_msg_processed_delay;
  }
}

export class OrgIntegration {
  integrationLid: string;
  orgLid: string;
  requestType: number;
  requestTypeName: string;
  maxAllowedStale: number;
  maxAllowedStaleBusyPeriod: number;
  busyPeriodStart: string;
  busyPeriodEnd: string;
  status: OrgIntegrationRequestTypeStatus | undefined;
  stale: boolean;

  constructor(d: any) {
    this.integrationLid = d.integration_lid;
    this.orgLid = d.org_lid;
    this.requestType = d.request_type;
    this.requestTypeName = d.request_type_name;
    this.maxAllowedStale = d.max_allowed_stale;
    this.maxAllowedStaleBusyPeriod = d.max_allowed_stale_busy_period;
    this.busyPeriodStart = d.busy_period_start;
    this.busyPeriodEnd = d.busy_period_end;
    if (d.status) this.status = new OrgIntegrationRequestTypeStatus(d.status);
    this.stale = d.stale;
  }
}

export class GetOrgIntegrationStatusResponse {
  orgLid: string;
  connectivityStatus: number;
  integrations: Array<OrgIntegration>;

  constructor(d: any) {
    this.orgLid = d.org_lid;
    this.connectivityStatus = d.connectivity_status;
    this.integrations = d.integrations.map((i: any) => new OrgIntegration(i));
  }
}
