import zxcvbn from 'zxcvbn';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params, ParamMap } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import { AppState } from '../../app.service';
import { AuthService } from '../../data/auth/auth.service';

import { LoginUser } from '../../data/auth/auth.model';

import { BasePageComponent } from '@app/shared/base-page/base-page.component';

@Component({
  selector: 'login-reset',
  styleUrls: [ './login-reset.component.scss' ],
  templateUrl: './login-reset.component.html',
  providers: [AuthService]
})
export class LoginResetComponent extends BasePageComponent implements OnInit, OnDestroy {

  public token: string | null = '';
  public newPassword: string = '';
  public errorMessage?: string;
  public passwordSuggestions: string[] = [];
  public isLoading: boolean = false;
  public isSuccessful: boolean = false;
  public isPassOk: boolean = false;
  public user?: LoginUser;

  constructor(
    protected override _titleService: Title,
    protected override _router: Router,
    protected override _route: ActivatedRoute,
    protected override _translate: TranslateService,
    protected override _appState: AppState,
    private _authService: AuthService,
  ) {
    super(_titleService, _router, _route, _translate, _appState);
    this._baseUrl = '/password/reset';
    this._translate.get('LOGIN.TITLE_RESET_PASSWORD')
      .subscribe((t: string) => {
        this._titleService.setTitle(t);
      });
  }

  protected override _getParamsFromUrl(params: Params, queryParams: ParamMap, fragment: string) {
    this.token = queryParams.get('token');
  }

  public onPasswordChange(e: any) {
    const result = zxcvbn(this.newPassword);
    this.isPassOk = result.score >= 3;
    if (!this.isPassOk) {
      this.passwordSuggestions = Object.assign([], result.feedback.suggestions);
    } else {
      this.passwordSuggestions = [];
    }
  }

  public changePassword() {
    if (this.token) {
      this.isLoading = true;
      this._authService.changePasswordWithToken(this.token, this.newPassword)
        .subscribe(response => {
          this.isLoading = false;
          this.isSuccessful = true;
        },
        (error) => {
          this.isLoading = false;
          this.passwordSuggestions = [];
          switch(error.status) {
            case 400:
              this.errorMessage = this._translate.instant('LOGIN.WEAK_PASSWORD');
              break;

            default:
              this.errorMessage = this._translate.instant('LOGIN_RESET_PASSWORD_EXPIRED');
          }
        });
    }
  }
}
