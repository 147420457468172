<div class="leap-rail-app" (click)="onAppClicked()" [ngClass]="{'hidden': !isLoaded}">
  <generic-header [appId]="appId" [isOpened]="isSideBarOpened" (onMenuClicked)="onHeaderMenuClicked()"></generic-header>
  <div class="upgrade-container" *ngIf="appId.id == 2">
    <ng-container *ngIf="featureId != 1015">
      <div class="cta-container">
        {{ 'UPGRADE.BOARD_NOT_ENABLED' | translate }}
        {{ 'UPGRADE.SALES_ENABLE_APP' | translate | translateCut:0 }} <a href="mailto:sales@leaprail.com">{{ 'UPGRADE.SALES_ENABLE_APP' | translate | translateCut:1 }}</a> {{ 'UPGRADE.SALES_ENABLE_APP' | translate | translateCut:2 }}
      </div>
      <span class="image right"><img src="assets/img/screen_board.jpg" alt="" /></span>
      <p>{{ 'UPGRADE.BOARD_PART1' | translate }}</p>
      <p>{{ 'UPGRADE.BOARD_PART2' | translate }}.</p>
      <p>{{ 'UPGRADE.BOARD_PART3' | translate }}</p>
      <p>{{ 'UPGRADE.BOARD_PART4' | translate }}</p>
    </ng-container>
    <ng-container *ngIf="featureId == 1015">
      <div class="cta-container">
        {{ 'UPGRADE.BOARD_BLOCK_NOT_ENABLED' | translate }}
        {{ 'UPGRADE.SALES_ENABLE_FEATURE' | translate | translateCut:0 }} <a href="mailto:sales@leaprail.com">{{ 'UPGRADE.SALES_ENABLE_FEATURE' | translate | translateCut:1 }}</a> {{ 'UPGRADE.SALES_ENABLE_FEATURE' | translate | translateCut:2 }}
      </div>
      
      <img class="ms-md-3 float-md-end" src="assets/img/screen_block1.jpg" alt="" />
      <p>{{ 'UPGRADE.BOARD_BLOCK_PART1' | translate }}</p>
      <p>{{ 'UPGRADE.BOARD_BLOCK_PART2' | translate }}</p>
      <p>{{ 'UPGRADE.BOARD_BLOCK_PART3' | translate }}</p>

      <img class="me-md-3 float-md-start" src="assets/img/screen_block2.jpg" alt="" />
      <p class="clearfix">{{ 'UPGRADE.BOARD_BLOCK_PART4' | translate }}</p>
      
    </ng-container>
    <div class="upsell-message">
      <div class="button-container">
        <button class="btn btn-secondary btn-continue" (click)="continue()">{{ 'CONTINUE' | translate }}</button>
      </div>
    </div>
  </div>
  <div class="upgrade-container" *ngIf="appId.id == 3">
    <div class="cta-container">
      {{ 'UPGRADE.SHIFT_NOT_ENABLED' | translate }}
      {{ 'UPGRADE.SALES_ENABLE_APP' | translate | translateCut:0 }} <a href="mailto:sales@leaprail.com">{{ 'UPGRADE.SALES_ENABLE_APP' | translate | translateCut:1 }}</a> {{ 'UPGRADE.SALES_ENABLE_APP' | translate | translateCut:2 }}
    </div>
    <span class="image right"><img src="assets/img/screen_shift.jpg" alt="" /></span>
    <p>{{ 'UPGRADE.SHIFT_PART1' | translate }}</p>
    <p>{{ 'UPGRADE.SHIFT_PART2' | translate }}</p>
    <p>{{ 'UPGRADE.SHIFT_PART3' | translate }}</p>
    <div class="upsell-message">
      <div class="button-container">
        <button class="btn btn-secondary btn-continue" (click)="continue()">{{ 'CONTINUE' | translate }}</button>
      </div>
    </div>
  </div>
  <div class="upgrade-container" *ngIf="appId.id == 4">
    <div class="cta-container" *ngIf="!featureId">
        {{ 'UPGRADE.ANALYTICS_NOT_ENABLED' | translate }}
        {{ 'UPGRADE.SALES_ENABLE_APP' | translate | translateCut:0 }} <a href="mailto:sales@leaprail.com">{{ 'UPGRADE.SALES_ENABLE_APP' | translate | translateCut:1 }}</a> {{ 'UPGRADE.SALES_ENABLE_APP' | translate | translateCut:2 }}
    </div>
    <div class="cta-container"  *ngIf="featureId">
      {{ 'UPGRADE.ANALYTICS_EXTRA_NOT_ENABLED' | translate }} 
      {{ 'UPGRADE.SALES_ENABLE_FEATURE' | translate | translateCut:0 }} <a href="mailto:sales@leaprail.com">{{ 'UPGRADE.SALES_ENABLE_FEATURE' | translate | translateCut:1 }}</a> {{ 'UPGRADE.SALES_ENABLE_FEATURE' | translate | translateCut:2 }}
    </div>
    <span class="image right"><img src="assets/img/screen_analytics.jpg" alt="" /></span>
    <p>{{ 'UPGRADE.ANALYTICS_PART1' | translate }}</p>
    <p>{{ 'UPGRADE.ANALYTICS_PART2' | translate }}</p>
    <p>{{ 'UPGRADE.ANALYTICS_PART3' | translate }}</p>
    <p>{{ 'UPGRADE.ANALYTICS_PART4' | translate }}</p>
    <div class="upsell-message">
        <div class="button-container">
            <button class="btn btn-secondary btn-continue" (click)="continue()">{{ 'CONTINUE' | translate }}</button>
        </div>
    </div>
  </div>
  <div class="upgrade-container" *ngIf="appId.id == 5">
    <div class="cta-container" *ngIf="!featureId">
        {{ 'UPGRADE.SURGERY_NOT_ENABLED' | translate }}
        {{ 'UPGRADE.SALES_ENABLE_APP' | translate | translateCut:0 }} <a href="mailto:sales@leaprail.com">{{ 'UPGRADE.SALES_ENABLE_APP' | translate | translateCut:1 }}</a> {{ 'UPGRADE.SALES_ENABLE_APP' | translate | translateCut:2 }}
    </div>
    <div class="cta-container"  *ngIf="featureId">
      {{ 'UPGRADE.SURGERY_EXTRA_NOT_ENABLED' | translate }}
      {{ 'UPGRADE.SALES_ENABLE_FEATURE' | translate | translateCut:0 }} <a href="mailto:sales@leaprail.com">{{ 'UPGRADE.SALES_ENABLE_FEATURE' | translate | translateCut:1 }}</a> {{ 'UPGRADE.SALES_ENABLE_FEATURE' | translate | translateCut:2 }}
    </div>
    <ng-container [ngSwitch]="featureId">
      <ng-container *ngSwitchCase="1016">
        <div class="row">
          <div class="col h3">{{ 'UPGRADE.FAMILY_NOTIFICATION_TITLE' | translate }}</div>
        </div>
        <div class="row">
          <div class="col-sm-9">
            <p>{{ 'UPGRADE.FAMILY_NOTIFICATION_PART1' | translate }}</p>
          </div>
          <div class="col-sm-3">
            <img class="img-fluid p-1" src="assets/img/screen_patient_notification.png" alt="" />
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="1017">
        <div class="row">
          <div class="col h3">{{ 'UPGRADE.DURATION_ACCURACY_TITLE' | translate }}</div>
        </div>
        <div class="row">
          <div class="col-sm">
            <p>{{ 'UPGRADE.DURATION_ACCURACY_PART1' | translate }}</p>
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="5004">
        <div class="row">
          <div class="col h3">{{ 'UPGRADE.BLOCK_UTILIZATION_TITLE' | translate }}</div>
        </div>
        <div class="row">
          <div class="col-sm-8">
            <p>{{ 'UPGRADE.BLOCK_UTILIZATION_PART1' | translate }}</p>
            <p>{{ 'UPGRADE.BLOCK_UTILIZATION_2' | translate }}</p>
          </div>
          <div class="col-sm-4">
            <img class="img-fluid p-1" src="assets/img/screen_block_prediction.png" alt="" />
          </div>
        </div>
        <div class="row">
          <div class="col h3">{{ 'UPGRADE.BLOCK_RELEASE_TITLE' | translate }}</div>
        </div>
        <div class="row">
          <div class="col-sm-4">
            <img class="img-fluid p-1" src="assets/img/screen_block_release.png" alt="" />
          </div>
          <div class="col-sm-8">
            <p>{{ 'UPGRADE.BLOCK_RELEASE_PART1' | translate }}</p>
            <p>{{ 'UPGRADE.BLOCK_RELEASE_PART2' | translate }}</p>
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="6011">
        <p>{{ 'UPGRADE.VENDOR_PART1' | translate }}</p>
        <p>{{ 'UPGRADE.VENDOR_PART2' | translate }}</p>    
        <p>{{ 'UPGRADE.VENDOR_PART3' | translate }}</p>
      </ng-container>
      <ng-container *ngSwitchCase="6021">
        <p>{{ 'UPGRADE.BOOKING_REQUEST_PART1' | translate }}</p>
        <p>{{ 'UPGRADE.BOOKING_REQUEST_PART2' | translate }}</p>
        <p>{{ 'UPGRADE.BOOKING_REQUEST_PART3' | translate }}</p>
        <p>{{ 'UPGRADE.BOOKING_REQUEST_PART4' | translate }}</p>
        <p>{{ 'UPGRADE.BOOKING_REQUEST_PART5' | translate }}</p>
      </ng-container>
    </ng-container>
    <div class="upsell-message">
        <div class="button-container">
            <button class="btn btn-secondary btn-continue" (click)="continue()">{{ 'CONTINUE' | translate }}</button>
        </div>
    </div>
  </div>
</div>

<app-sidebar [isOpened]="isSideBarOpened"></app-sidebar>
