import moment from 'moment';
import zxcvbn from 'zxcvbn';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';

import { NgxPermissionsService } from 'ngx-permissions';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';

import { AuthService } from '../data/auth/auth.service';
import { LoginUser, LoginUserCache, LoginUserOrg } from '../data/auth/auth.model';
import { AppState } from '../app.service';

@Component({
  selector: 'login',
  styleUrls: [ './login.component.scss' ],
  templateUrl: './login.component.html',
  providers: [CookieService, AuthService]
})
export class LoginComponent implements OnInit {

  public email: string = '';
  public password: string = '';
  public newPassword: string = '';
  public errorMessage?: string;
  public passwordSuggestions: string[] = [];
  public isLoading: boolean = false;
  public isLoggedIn: boolean = false;
  public isPassChange: boolean = false;
  public isPassOk: boolean = false;
  public returnUrl?: string = undefined;
  public ssoUrl?: string;
  public user?: LoginUser;
  private _emailCheck = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  constructor(
    private _titleService: Title,
    private _authService: AuthService,
    private _cookieService: CookieService,
    private _route: ActivatedRoute,
    private _appState: AppState,
    private _router: Router,
    private _permissionsService: NgxPermissionsService,
    private _translate: TranslateService,
  ) {}

  public ngOnInit() {
    const returnUrl = this._route.snapshot.queryParams['returnUrl'];
    this._translate.get('LOGIN.TITLE')
      .subscribe((t: string) => {
        this._titleService.setTitle(t);
      });
    if (returnUrl) {
      this.returnUrl = returnUrl;
      this.ssoUrl = `/login/sso?returnUrl=${encodeURIComponent(returnUrl)}`
    } else {
      this.ssoUrl = `/login/sso`
    }
  }

  public onPasswordChange(e: any) {
    const result = zxcvbn(this.newPassword);
    this.isPassOk = result.score >= 3;
    if (!this.isPassOk) {
      this.passwordSuggestions = Object.assign([], result.feedback.suggestions);
    } else {
      this.passwordSuggestions = [];
    }
  }

  public changePassword() {
    this.isLoading = true;
    this._authService.changePassword(this.email, this.password, this.newPassword)
      .subscribe((data: LoginUser) => {
        this.user = data;
        if (data.orgs.length === 1) {
          this.proceedWithOrg(data.orgs[0]);
        } else {
          this.isLoggedIn = true;
        }
      },
      (error) => {
        this.isLoading = false;
        this.passwordSuggestions = [];
        switch(error.status) {
          case 400:
            this.errorMessage = this._translate.instant('LOGIN.WEAK_PASSWORD');
            break;

          default:
            this.errorMessage = this._translate.instant('LOGIN.CONTACT_ADMIN');
        }
      });
  }

  public login() {
    this.errorMessage = '';
    if (this.email.trim() === '' || this.password.trim() === '') {
      this.errorMessage = this._translate.instant('LOGIN.FILL_IN_BOTH');
      return;
    }
    if (!this._emailCheck.test(this.email)){
      this.errorMessage = this._translate.instant('LOGIN.ENTER_VALID_EMAIL');
      return;
    }
    this.isLoading = true;
    this._authService.login(this.email, this.password).subscribe(
      (data: LoginUser) => {
        this._cookieService.delete('lrSSO', '/');
        this.user = data;
        if (data.orgs.length === 0) {
          this.isLoading = false;
          this.errorMessage = this._translate.instant('LOGIN.ACCESS_EXPIRED');
        } else if (data.orgs.length === 1) {
          this.proceedWithOrg(data.orgs[0]);
        } else {
          this.isLoggedIn = true;
        }
      },
      (error) => {
        this.isLoading = false;
        switch(error.status) {
          case 449:
            switch(error.error.type) {
              case 'MustChangePasswordException':
                this.isPassChange = true;
                break;

              case 'AccountLockedException':
                this.errorMessage = this._translate.instant('LOGIN.ACCOUNT_LOCKED');
                break;
            }
            break;
            
          default:
            this.errorMessage = this._translate.instant('LOGIN.INCORRECT_EMAIL_PASSWORD');
        }
      }
    );
  }

  public proceedWithOrg(org: LoginUserOrg) {
    if (!this.returnUrl) {
      this.returnUrl = this._authService.getAppPath(org.defaultAppId);
    }

    const userCache: LoginUserCache | undefined = this.user ? new LoginUserCache({
      user_lid: this.user.userLid,
      display_name: this.user.displayName,
      org: org.toJson()
    }) : undefined;

    if (userCache) {
      this._cookieService.set('lrUser', JSON.stringify(userCache.toJson()), {
        expires: moment().add(2, 'day').toDate()
      });
    }

    if (this.user) {
      this._appState.set('user_name', this.user.displayName);
      this._appState.set('user_id', this.user.userLid);
    }
    this._appState.set('orgId', org.orgLid);
    this._appState.set('provider', org.providerToJson());
    this._appState.set('org', org.toJson());
    this._permissionsService.loadPermissions(org.permissions);
    this._router.navigateByUrl(this.returnUrl);
  }
}
