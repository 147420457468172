import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { CookieService } from 'ngx-cookie-service';
import { NgxPermissionsModule, NgxPermissionsService } from 'ngx-permissions';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { MomentModule } from 'ngx-moment';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './framework/shared.module';
import { UIModule } from './ui/ui.module';
import { SurgeryBlockOnDateModalModule } from './shared/surgery-block-on-date-modal/surgery-block-on-date-modal.module';

/*
 * Platform and Environment providers/directives/pipes
 */
import { AppState, InternalStateType } from './app.service';
import { DatadogErrorHandlerService } from './service/datadog-error-handler.service';
import { DataService } from './data/data.service';
import { AnnouncementService } from './data/announcement.service';
import { AuthService } from './data/auth/auth.service';
import { AppGuard } from './data/app-guard.service';
import { LRTranslateHttpLoader } from './data/translate-loader';
import { OrgCacheService } from './data/org-cache.service';

import { LoginUserCache } from './data/auth/auth.model';

import { AppComponent } from './app.component';
import { HomeComponent } from './home';
import { LoginComponent } from './login';
import { LoginAdminComponent } from './login/admin';
import { LoginSSOComponent } from './login/sso';
import { LoginResetComponent } from './login/reset';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { NotAuthorizedComponent } from './not-authorized';
import { StatusComponent } from './status';
import { UpgradeComponent } from './upgrade';
import { NoContentComponent } from './no-content';

export function appInitializerFactory(as: AppState, cs: CookieService, ps: NgxPermissionsService, ts: TranslateService) {
  return () => {
    const cachedUser = cs.get('lrUser');
    if (cachedUser) {
      const user: LoginUserCache = new LoginUserCache(JSON.parse(cachedUser));
      as.set('orgId', user.org.orgLid);
      as.set('provider', user.org.providerToJson());
      as.set('org', user.org.toJson());
      ps.loadPermissions(user.org.permissions);
    }

    ts.setDefaultLang('en');
    const browserLang = ts.getBrowserLang();
    if (browserLang) {
      firstValueFrom(ts.use(browserLang.match(/en|es/) ? browserLang : 'en')).then(() => {
        ;
      }).catch((err) => {
        console.error('Error setting language:', err);
        ;
      });
    }
  };
}

// Application wide providers
const APP_PROVIDERS = [
  AppState,
  DataService,
  OrgCacheService,
  AuthService,
  AnnouncementService,
  AppGuard,
  CookieService,
  {
    provide: APP_INITIALIZER,
    useFactory: appInitializerFactory,
    deps: [AppState, CookieService, NgxPermissionsService, TranslateService],
    multi: true
  },
  { provide: ErrorHandler, useClass: DatadogErrorHandlerService }
];

type StoreType = {
  state: InternalStateType,
  restoreInputValues: () => void,
  disposeOldHosts: () => void
};

// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient, cs: CookieService) {
  return new LRTranslateHttpLoader(http, cs, './assets/i18n/', '.json');
}

@NgModule({ 
  bootstrap: [AppComponent],
  declarations: [
      AppComponent,
      HomeComponent,
      LoginComponent,
      LoginAdminComponent,
      LoginSSOComponent,
      LoginResetComponent,
      MaintenanceComponent,
      NotAuthorizedComponent,
      StatusComponent,
      UpgradeComponent,
      NoContentComponent,
  ], imports: [BrowserModule,
      BrowserAnimationsModule,
      FormsModule,
      ReactiveFormsModule,
      NgxPermissionsModule.forRoot(),
      BsDatepickerModule.forRoot(),
      ProgressbarModule.forRoot(),
      ModalModule.forRoot(),
      BsDropdownModule.forRoot(),
      TooltipModule.forRoot(),
      AppRoutingModule,
      NgIdleKeepaliveModule.forRoot(),
      MomentModule,
      TranslateModule.forRoot({
          defaultLanguage: 'en',
          loader: {
              provide: TranslateLoader,
              useFactory: (createTranslateLoader),
              deps: [HttpClient, CookieService]
          }
      }),
      SharedModule.forRoot(),
      UIModule,
      SurgeryBlockOnDateModalModule], providers: [
      APP_PROVIDERS,
      provideHttpClient(withInterceptorsFromDi())
  ] 
})
export class AppModule { }
