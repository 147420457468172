import moment from 'moment';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';

import { CookieService } from 'ngx-cookie-service';
import { NgxPermissionsService } from 'ngx-permissions';

import { AppState } from '@app/app.service';
import { AuthService } from '@app/data/auth/auth.service';
import { LoginUser, LoginUserCache } from '@app/data/auth/auth.model';

@Component({
  templateUrl: './admin.component.html',
  providers:[ CookieService ],
})
export class LoginAdminComponent implements OnInit {

  public token: string | null = null;
  public user2Imitate: string | null = null;
  public role2Imitate: string | null = null;

  public isLoading: boolean = true;

  constructor(
    private _authService: AuthService,
    private _cookieService: CookieService,
    private _route: ActivatedRoute,
    private _appState: AppState,
    private _router: Router,
    private _permissionsService: NgxPermissionsService,
  ) {}

  public ngOnInit() {
    this._route.queryParamMap
      .subscribe((queryParams: ParamMap) => {
        this.token = queryParams.get('token');
        this.user2Imitate = queryParams.get('user_to_imitate');
        this.role2Imitate = queryParams.get('role_to_imitate');
        if (this.token) this._login();
      },
      (err) => {
        this.isLoading = false;
        console.error(err);
      });
  }

  private _login() {
    if (this.token) {
      this._authService.loginAdmin(this.token, this.user2Imitate, this.role2Imitate)
        .subscribe((data: LoginUser) => {
          const org = data.orgs[0];
          const nextUrl = this._authService.getAppPath(org.defaultAppId);

          const userCache: LoginUserCache = new LoginUserCache({
            user_lid: data.userLid,
            display_name: data.displayName,
            org: org.toJson()
          });
          this._cookieService.set('lrUser', JSON.stringify(userCache.toJson()), {
            expires: moment().add(2, 'day').toDate()
          });
      
          this._appState.set('user_name', userCache.displayName);
          this._appState.set('user_id', userCache.userLid);
          this._appState.set('orgId', org.orgLid);
          this._appState.set('provider', org.providerToJson());
          this._appState.set('org', org.toJson());
          this._permissionsService.loadPermissions(org.permissions);
          this._router.navigateByUrl(nextUrl);
        },
        (err) => {
          this.isLoading = false;
          console.error(err);
        });
    }
  }
}