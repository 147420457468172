import moment from 'moment';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { TranslateService } from '@ngx-translate/core';

import { AppState } from '@app/app.service';
import { IntegrationService } from '@app/data/integration/integration.service';

import { AppId } from '@app/data/auth/app-id.model';
import { Breadcrumb } from '@app/data/navigation/navigation.model';
import { GetOrgIntegrationStatusResponse, OrgIntegration } from '@app/data/integration/integration.model';

@Component({
  selector: 'status',
  styleUrls: [ './status.component.scss' ],
  templateUrl: './status.component.html',
  providers: [IntegrationService]
})
export class StatusComponent implements OnInit {
  public isSideBarOpened = false;
  public isLoaded = false;
  
  public appId: AppId;
  public lastUpdated: moment.Moment;
  public breadcrumbs: Array<Breadcrumb> = [];

  public connectivityStatus: any;
  public integrations?: Array<OrgIntegration>;
  
  private _orgLid!: string;
  
  constructor(
    private _titleService: Title,
    protected _translate: TranslateService,
    private _appState: AppState,
    private _integrationService: IntegrationService,
  ) {
    this.appId = new AppId(6);
    this.lastUpdated = moment();
    this.breadcrumbs = [
      new Breadcrumb(this._translate.instant('STATUS.CURRENT_STATUS')),
    ];
  }

  public onAppClicked() {
    this.isSideBarOpened = false;
  }

  public onHeaderMenuClicked() {
    this.isSideBarOpened = !this.isSideBarOpened;
  }

  ngOnInit() {
    this._orgLid = this._appState.get('orgId');
    this._titleService.setTitle(this._translate.instant('STATUS.TITLE'));
    this._integrationService.GetOrgIntegrationStatus(this._orgLid)
      .subscribe((response: GetOrgIntegrationStatusResponse) => {
        this.connectivityStatus = response.connectivityStatus;
        this.integrations = response.integrations;
        this.isLoaded = true;
      }, 
      (err) => {
        console.error(err);
      });
  }
}
