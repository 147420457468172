<div class="login-container">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="form-block">
          <div class="logo"></div>
          <div class="form-horizontal" *ngIf="!isSuccessful">
            <form (ngSubmit)="changePassword()" class="padded-form" autocomplete="off">
              <div class="form-group" [ngClass]="{'has-error': newPassword !== '' && !isPassOk}">
                <div class="info-content">
                  {{ 'LOGIN.CHANGE_PASSWORD' | translate }}
                </div>
                <input type="password" name="pwd"
                  placeholder="{{ 'LOGIN.NEW_PASSWORD' | translate }}" required
                  (keyup)="onPasswordChange($event)"
                  [(ngModel)]="newPassword" class="form-control" />
                <div class="error-message mt">
                  <div *ngFor="let suggestion of passwordSuggestions">{{ suggestion }}</div>
                  <div>{{ errorMessage }}</div>
                </div>
              </div>
              <div class="form-group">
                <div class="button-row mt" [ngClass]="{'is-loading': isLoading}">
                  <button type="submit" [disabled]="newPassword === '' || !isPassOk" class="btn btn-secondary btn-submit">{{ 'CONTINUE' | translate }}</button>
                  <i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
                </div>
              </div>
            </form>
          </div>
          <div class="info-block" *ngIf="isSuccessful">
            <div class="info-content">
              {{ 'LOGIN.RESET_SUCCESS' | translate }}
            </div>
            <div class="org-list-item">
              <a class="btn btn-secondary btn-org" [routerLink]="['/', 'login']">{{ 'CONTINUE' | translate }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 text-center login-footer" [innerHTML]="'COPYRIGHT' | translate"></div>
    </div>
  </div>
</div>