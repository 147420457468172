<div class="leap-rail-app" (click)="onAppClicked()" [ngClass]="{'hidden': !isLoaded}">
  <generic-header [appId]="appId" [isOpened]="isSideBarOpened" (onMenuClicked)="onHeaderMenuClicked()"></generic-header>
  <div class="unauth-message">
    {{ 'NOT_AUTHORIZED' | translate }}
    <div class="button-container">
      <a class="btn btn-secondary btn-continue" [routerLink]="continueRouterLink">{{ 'CONTINUE' | translate }}</a>
    </div>
  </div>
</div>

<app-sidebar [isOpened]="isSideBarOpened"></app-sidebar>
