import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { INTEGRATION_API_URL, DataService } from '../data.service';
import { GetOrgIntegrationStatusResponse } from './integration.model';

@Injectable()
export class IntegrationService {

  constructor (
    private _httpClient: HttpClient,
    private _dataService: DataService
  ) {};

  public GetOrgIntegrationStatus(orgLid: string): Observable<GetOrgIntegrationStatusResponse> {
    return this._httpClient.get(`${INTEGRATION_API_URL}/org/${orgLid}/status`, this._dataService.getApiRequestHeader())
      .pipe(
        map((response: any) => new GetOrgIntegrationStatusResponse(response['data'])),
        catchError(((error: HttpErrorResponse) => this._dataService.handleHttpError(error)))
      );
  }
}
